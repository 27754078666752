/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

//  "node_modules/@angular/material/prebuilt-themes/indigo-pink.css",
//  "node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css",
//  "node_modules/@angular/material/prebuilt-themes/pink-bluegrey.css",
//  "node_modules/@angular/material/prebuilt-themes/purple-green.css"


@use '@angular/material' as mat;
@use '@angular/material-experimental' as matx;


// $m3-red-palette
// $m3-green-palette
// $m3-blue-palette
// $m3-yellow-palette
// $m3-cyan-palette
// $m3-magenta-palette
// $m3-orange-palette
// $m3-chartreuse-palette
// $m3-azure-palette
// $m3-violet-palette
// $m3-rose-palette


$m3-dark-theme: matx.define-theme((
  color: ( 
    theme-type: dark,
    primary: matx.$m3-blue-palette,
    tertiary: matx.$m3-cyan-palette,
  )
));

$m3-light-theme: matx.define-theme((
  color: (
    primary: matx.$m3-red-palette,
    tertiary: matx.$m3-red-palette,
    )
));

.mdc-text-field--filled.mdc-text-field--disabled .mdc-text-field__input {
  color: white !important;
  cursor: not-allowed;
  border: none !important;
}

.mdc-text-field--filled.mdc-text-field--disabled {
  border: none !important;
  background-color: transparent !important;

  // this works
  // mat-label {
  //   color: white !important;
  // }

  // .mdc-line-ripple, .mdc-line-ripple--deactivating {
  //   display: none !important;
  // }
}


// .mat-mdc-text-field-wrapper {
//   padding-left: 8px !important;
//   height: 51px !important;
// }

.mdc-text-field--filled .mdc-line-ripple::before {
  border-bottom-color: transparent !important;
}

.mdc-text-field--filled.mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-filled-text-field-disabled-active-indicator-color) !important;
}

.mdc-text-field--filled {
  border-top-left-radius: 1px !important;
  border-top-right-radius: 1px !important;
  border-bottom-left-radius: 1px !important;
  border-bottom-right-radius: 1px !important;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none !important;
}

.mat-expansion-indicator {
  position: relative;
  left: -5px;
}

.mat-expansion-indicator::after {
  color: var(--mdc-filled-text-field-focus-active-indicator-color) !important;  
  border-color: var(--mdc-filled-text-field-focus-active-indicator-color) !important;
}

 .mat-expansion-panel-body {
  padding: 0 !important;
  padding-top: 4px !important;
  padding-bottom: 15px !important;
 }

 .mat-expansion-panel-header[aria-disabled=true] {
  color: white !important;
 }

 .width-max {
  width: 100vw !important;
 }

 .mat-mdc-dialog-container .mdc-dialog__surface {
  // width: 100vw !important;  
  width: 800px !important;
  // width: 595px !important;
  max-width: unset !important;

  @media only screen and (max-width: 800px) {
    width: 100% !important;
  }
}

// more like fixes
mat-tab-body {
  height: 100% !important;
}

.mat-mdc-tab-body {
  height: 100% !important;
}


.dark-theme {
  @include mat.all-component-themes($m3-dark-theme);
}

.light-theme {
  @include mat.all-component-themes($m3-light-theme);
}

.mat-mdc-card, mat-card {
  // border: 1px solid #4d3e3e !important;
}

.mat-mdc-card-header {
  display: flex;
  padding: 10px 10px 0 !important;
  padding-top: 0 !important;
}

.text-material {
  color: var(--mdc-filled-text-field-focus-active-indicator-color);
}

.highlight-class {
  color: var(--mdc-filled-text-field-focus-active-indicator-color) !important;
}

body {
  --mdc-filled-text-field-caret-color: red;
  --mdc-filled-text-field-focus-active-indicator-color: greenyellow; // !! asta este pentru mat input (sau doar option) active bottom border color
  --mdc-filled-text-field-focus-label-text-color: rgba(31, 114, 162, 0.87);

  --ion-background-color: var(--mat-app-background-color) !important;

  // not sure here, mat tab group fixing
  // --mdc-tab-indicator-active-indicator-color: blue !important;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #7b1fa2;
  --mat-tab-header-active-ripple-color: var(--mdc-filled-text-field-focus-active-indicator-color);
  --mat-tab-header-inactive-ripple-color: var(--mdc-filled-text-field-focus-active-indicator-color);
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #7b1fa2;
  --mat-tab-header-active-hover-label-text-color: #7b1fa2;
  --mat-tab-header-active-focus-indicator-color: pink;
  --mat-tab-header-active-hover-indicator-color: red;
}

// works for mat multiple selection
.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--mdc-filled-text-field-focus-active-indicator-color);
  // var(--mdc-filled-text-field-focus-active-indicator-color)
  // var(--mdc-filled-text-field-active-indicator-color)
}

.util-mat-app-background-color {
  background-color: var(--mat-app-background-color);
}

/* start fixing mat tab group */

// --mdc-tab-indicator-active-indicator-color: #7b1fa2;
// --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
// --mat-tab-header-pagination-icon-color: #fff;
// --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
// --mat-tab-header-active-label-text-color: #7b1fa2;
// --mat-tab-header-active-ripple-color: #7b1fa2;
// --mat-tab-header-inactive-ripple-color: #7b1fa2;
// --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
// --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
// --mat-tab-header-active-focus-label-text-color: #7b1fa2;
// --mat-tab-header-active-hover-label-text-color: #7b1fa2;
// --mat-tab-header-active-focus-indicator-color: #7b1fa2;
// --mat-tab-header-active-hover-indicator-color: #7b1fa2;

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  // --mdc-tab-indicator-active-indicator-color: blue !important;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #7b1fa2;
  --mat-tab-header-active-ripple-color: var(--mdc-filled-text-field-focus-active-indicator-color);
  --mat-tab-header-inactive-ripple-color: var(--mdc-filled-text-field-focus-active-indicator-color);
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #7b1fa2;
  --mat-tab-header-active-hover-label-text-color: #7b1fa2;
  --mat-tab-header-active-focus-indicator-color: pink;
  --mat-tab-header-active-hover-indicator-color: red;
}

ion-modal {
  --height: 92vh;
  --width: 100vw;
}

// .block {
//   width: 100%;
//   height: 500px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

/* end fixing mat tab group */

// @use '@angular/material-experimental' as matx;

// $theme: matx.define-theme((
//   color: (
//     theme-type: dark,
//     // primary: matx.$m3-rose-palette,
//     // primary: matx.$m3-violet-palette,
//   ),
//   typography: (
//     brand-family: 'Comic Sans',
//     bold-weight: 900
//   ),
//   density: (
//     scale: -1
//   )
// ));
 
@import "~@angular/material/prebuilt-themes/purple-green.css"; // Or another pre-built theme
 // Then include your custom theme styles after


 

// @use '@angular/material' as mat;

// // Custom Crimson Red Primary Palette
// $custom-crimson-primary: (
//   50: #f9e6e6,
//   100: #f0bcbc,
//   200: #e68f8f,
//   300: #db6262,
//   400: #d43a3a,
//   500: #cd1212, // Chosen primary crimson color
//   600: #c70f0f,
//   700: #c00c0c,
//   800: #b90a0a,
//   900: #af0505,
//   A100: #ff9a9a,
//   A200: #ff6767,
//   A400: #ff3434,
//   A700: #ff1a1a,
//   contrast: (
//     50: #000000,
//     100: #000000,
//     200: #000000,
//     300: #000000,
//     400: #ffffff,
//     500: #ffffff,
//     600: #ffffff,
//     700: #ffffff,
//     800: #ffffff,
//     900: #ffffff,
//     A100: #000000,
//     A200: #000000,
//     A400: #000000,
//     A700: #000000,
//   ),
// );


// // Using predefined palettes for accent (Blue Grey) and warn (Red)
// $custom-accent: mat.define-palette(mat.$blue-grey-palette);
// $custom-warn: mat.define-palette(mat.$red-palette);


// // Define the custom dark theme
// $custom-dark-theme: mat.define-dark-theme((
//   color: (
//     primary: mat.define-palette($custom-crimson-primary),
//     accent: $custom-accent,
//     warn: $custom-warn,
//   )
// ));

// // Apply the custom theme to all Angular Material components
// @include mat.all-component-themes($custom-dark-theme);



// .mdc-text-field--filled:not(.mdc-text-field--disabled) {
//   background: #232323 !important;
// }


//// modified angular materials

.mat-mdc-form-field-focus-overlay {
  background: transparent !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label, .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label--float-above {
  color: rgb(252, 238, 238) !important;
  font-size: 20px !important;

  mat-label {
    color: white !important;
  }

  .mdc-line-ripple, .mdc-line-ripple--deactivating {
    display: none !important;
  }
}

// mdc-line-ripple mdc-line-ripple--deactivating - hide this
// mat-label - color this white


// .mat-mdc-raised-button:not(:disabled) {
  // }
  
.mat-mdc-raised-button.mat-primary {
  background-color: #DC143C !important;
}

.mdc-tab__text-label {
  color: rgb(193, 193, 193) !important;
  font-size: 20px;
}

.mdc-tab.mat-mdc-tab.mat-mdc-focus-indicator.mdc-tab--active.mdc-tab-indicator--active {
  .mdc-tab__text-label {
    color: rgb(255, 241, 241) !important;
    font-size: 20px;
  }
}

.mdc-tab-indicator .mdc-tab-indicator__content {
  border-color: rgb(255, 241, 241) !important;
}

/* Global styles.css or component-specific CSS file */
input::-webkit-input-placeholder {
  color: white !important;
  opacity: 1; /* Override to make sure the placeholder is always visible */
}

input:-moz-placeholder { /* Firefox 18- */
  color: white !important;
  opacity: 1;
}

input::-moz-placeholder { /* Firefox 19+ */
  color: white !important;
  opacity: 1;
}

input:-ms-input-placeholder {
  color: white !important;
  opacity: 1;
}

input::placeholder {
  color: white !important;
  opacity: 1; /* Standard: For most modern browsers */
}

/* Changing cursor color */
input {
   caret-color: white !important;
}


// .mat-mdc-option .mdc-list-item .mat-mdc-option-multiple .mdc-list-item--selected {
//   color: #DC143C !important;
//   span {
//     color: #DC143C !important;
    
//   }
//   .mdc-list-item__primary-text {
    
//     color: #DC143C !important;
//   }
// }

.mdc-list-item__primary-text {
    
  color: white !important;
}

///// above good!!!!!
/// 
/// 
/// 


// .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--filled.mdc-text-field--focused {
//   border-bottom: 2px solid red;
// }

// end try again

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";




/* angular material */
// @import "@angular/material/prebuilt-themes/deeppurple-amber.css";

// @use '@angular/material' as mat;

// $my-palette: mat.$indigo-palette;

// @use '@angular/material' as mat;

// @include mat.core();

// $indigo-palette: (
//  50: #e8eaf6,
//  100: #c5cae9,
//  200: #9fa8da,
//  300: #7986cb,
//  // ... continues to 900
//  contrast: (
//    50: rgba(black, 0.87),
//    100: rgba(black, 0.87),
//    200: rgba(black, 0.87),
//    300: white,
//    // ... continues to 900
//  )
// );


// $my-palette: mat.$indigo-palette;

:root {
    // tailwind
	--app-max-width: 1175px;
}

ion-content {
    --width: 500px;
}

ion-menu {
    --width: 80%;

    .is-menu & {
      --width: 30%;
    }

    @media screen and (max-width: 376px) {
      --width: 375px !important;
    }

    @media screen and (max-width: 600px) {
        --width: 90% !important;
    }
}

// layout
#main-content {
    .tabs-inner {
        .ion-page {
            // padding-top: 56px;
            @media print {
              padding-top: 0;
            }
        }

    }
}

.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;

    // padding-top: constant(safe-area-inset-top); /* Status bar height on iOS 11.0 */
    // padding-top: env(safe-area-inset-top);


    &.safe-area {
        padding-top: calc(constant(safe-area-inset-top) + 56px);
        padding-top: calc(env(safe-area-inset-top) + 56px);
    }
    
    // height: 100%;
    // position: relative;
    width: 100%;
    // min-height: 700px; // not needed with ion content
  }

  .safe-area {
    padding-top: calc(constant(safe-area-inset-top) + 56px);
    padding-top: calc(env(safe-area-inset-top) + 56px);
  }
  
  .pageContent {
    // margin-top: 64px;
    justify-content: center;
  
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 1 auto;
    flex: 0 auto;
    padding: 15px;
  
    @apply max-w-app-max-width;
    width: 100%;
  }


  // angular material

  .tabs-height {
    // min-height: calc(90vh - 56px);
  }

  // ionic

  .input-error {
    --border-color: red !important;
  }


  // utils

  .disable-interaction {
    pointer-events: none;
  }
  
  .disable-interaction a, 
  .disable-interaction button, 
  .disable-interaction ion-icon {
    pointer-events: all;
    user-select: none;
  }

  // fixes

  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


//// mat-selection-list
// .horizontal-list  { //.mat-list-base
//   display: flex !important;
//   flex-direction: row;
//   flex-wrap: wrap; 

//   /* Optional, if you want to wrap items */
//   align-items: center; /* Optional, to vertically align items */
//   overflow-x: scroll !important;

//   mat-list-option, mat-list-item {
//     width: 200px;
//   }

//   .mdc-list-item__end {
//     display: none;
//   }
// }

.horizontal-list {
  display: flex !important;
  flex-direction: row !important;
  overflow-x: auto !important;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on touch devices */
}

.horizontal-list mat-list-item {
  flex: 0 0 auto !important; /* Prevent items from stretching. Change if necessary. */
  margin-right: 10px !important; /* Space between items */
  width: 200px !important;
}

// quill

.ql-toolbar {
  /* Change background color */
  background-color: transparent;
}

.ql-container {
  /* Set the font size */
  // font-size: 16px;
  /* Set the text color */
  // color: #333;
  /* Background color of the content area */
  background-color: transparent;
}

/// placeholder
.ql-blank {
  color: lightgray;// modifies the cursor color
}
.ql-editor.ql-blank {
  &:before {
    color: lightgray !important; // modifies the placeholder text
  }
}

/// icons toolbar
$icon-color: var(--mdc-filled-text-field-focus-active-indicator-color);


// 1 - works on text
.ql-toolbar button svg {
  fill: $icon-color !important; /* Change SVG icon colors */
}

.ql-toolbar .ql-picker-label,
.ql-toolbar button {
  color: $icon-color !important; /* Example: Change icon colors to blue */
}

// 2
.ql-snow .ql-stroke {
  stroke: $icon-color !important;
}

// 3
.ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill {
  fill: $icon-color !important;
}

// disabled
.ql-disabled {
  opacity: 0.8 !important;
}


.ql-toolbar.ql-snow {
  border-color: #414141 !important;
}

.ql-container.ql-snow {
  border-color: #414141 !important;
}


/*

  PRINT

*/

body {
  -ms-overflow-style: none;  /* For IE and Edge */
  scrollbar-width: none;  /* For Firefox */
}

::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

@media print {
  /* General settings to prevent overflow issues */
  body, html {
    background: white !important;
    color: black !important;
    width: auto;
    height: auto;
    overflow: visible !important;
    margin: 0;
    padding: 0;
  }

  /* Improved print styles for elements containing data */
  #container, app-root {
    display: block;
    width: 100% !important;
    height: auto !important;
    overflow: visible !important;
  }

  /* Ensure each deviz-print component is treated as a separate page */
  deviz-print, .deviz-print {
    height: 297mm; /* A4 height */
    width: 210mm; /* A4 width */
    position: relative !important;
    display: block !important;
    overflow: visible !important;
    page-break-before: always !important;
    page-break-after: avoid !important; /* Changed to 'avoid' to prevent extra blank page after the last component */
    page-break-inside: avoid; /* Prevent breaking inside the component */
    margin: 0mm; /* Resets margin */
  }

  /* Adjustments for table and text sizes within print components */
  .table-auto th, .table-auto td {
    border: 1px solid black !important;
    padding: 2px 4px !important;
  }

  .table-auto thead th, .table-auto tbody td {
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
  }

  /* Ionic-specific adjustments for print */
  ion-header, ion-backdrop, .tabbar, ion-footer {
    display: none !important;
  }

  ion-nav {
    contain: none;
    overflow: visible;
  }

  body, ion-router-outlet {
    position: initial !important;
    max-height: initial !important;
    overflow: auto !important;
    contain: none !important;
  }

  .ion-page, ion-content {
    display: initial !important;
    position: initial !important;
    --offset-bottom: unset !important;
  }
}

@page {
  size: A4;
  margin: 10mm 15mm; /* Adjusted margins for A4 */
}

/*

  EVERYTHING REDONE

*/

/*
  PER COMPONENT
*/

// <deviz-materials-list>
.list-controls-strip {
  // display: block;
  .hide-item-controls & {
      button.add-item-button {
        display: none !important;
      }
  }
}

// <deviz-materials-list-item>
.hide-item-controls {
  mat-expansion-panel-header {
    pointer-events: none;
    .mat-expansion-indicator {
      display: none;
    }
  }
}

// <deivz-form>
.hide-intrare {

  @media screen and (max-width: 1024px) {
    form {
      padding: 0 !important;
      margin: 0 !important;
      // margin-top: -70px !important;
    }
  }
  .hide-intrare-field {
    display: none !important;
  }
}

// <deviz-intrare>
.hide-toolbar {
  .ql-coolbar {
    display: none !important;
  }
}

/* The Modal (background) */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

/* Modal Content (image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Caption of Modal Image */
.caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
}

/* Add Animation */
.modal-content, .caption { 
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.2s;
  animation-name: zoom;
  animation-duration: 0.2s;
}

@-webkit-keyframes zoom {
  from {-webkit-transform: scale(0)} 
  to {-webkit-transform: scale(1)}
}

@keyframes zoom {
  from {transform: scale(0)} 
  to {transform: scale(1)}
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.1s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}


// <deviz-form>

.mobile-suggestions-box {
  opacity: 0;
  transform: translateY(-20px); /* Move it slightly up */
  transition: opacity 0.5s ease, transform 0.5s ease;
  visibility: hidden; /* Hide element visually */
}

.mobile-suggestions-box.show {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

//
#input-container-proprietar {
  transition: all 2s ease-out;
}

#advanced-form {
  &.activated-proprietar {
    display: block;
    margin-top: 0 !important;
    #input-container-proprietar {
      position: fixed;
      left: 0;
      
      // bottom: 0;
      // bottom: 356;

      width: 100%;
      // padding: 16px;
      // background: white;
      box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    }
    #input-container-masina {
      opacity: 0;
      pointer-events: none;
    }
    #input-container-adresa {
      opacity: 0;
      pointer-events: none;
    }
    #input-container-adresa-2 {
      opacity: 0;
      pointer-events: none;
    }
    #input-container-kilometraj {
      opacity: 0;
      pointer-events: none;
    }
    #input-container-executant {
      opacity: 0;
      pointer-events: none;
    }
    #input-container-data {
      opacity: 0;
      pointer-events: none;
    }
  }

  &.activated-masina {
    display: block;
    transition: all 2s ease-out;
    #input-container-masina {
      position: fixed;
      left: 0;
      
      // bottom: 0;
      // bottom: 356;

      width: 100%;
      // padding: 16px;
      // background: white;
      box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    }
    #input-container-proprietar {
      opacity: 0;
      pointer-events: none;
    }
    #input-container-adresa {
      opacity: 0;
      pointer-events: none;
    }
    #input-container-adresa-2 {
      opacity: 0;
      pointer-events: none;
    }
    #input-container-kilometraj {
      opacity: 0;
      pointer-events: none;
    }
    #input-container-executant {
      opacity: 0;
      pointer-events: none;
    }
    #input-container-data {
      opacity: 0;
      pointer-events: none;
    }
  }
}

#input-container-proprietar, #input-container-masina {
  transition: all 2s ease-out;
}

// future feature
// .rotate-that-shit {
//   transform: rotate(180deg); 
//   transform-origin: center;
// }

// ion-modal {
//   --height: 50vh;
//   ---max-height: 50vh;
// }

// <deviz-intrare>

.steps-container {
  height: calc(100% - 132px) !important; // 110 pana la final cand e 65
  /* padding-bottom: 40px; */
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;

  &.final-height {
    height: calc(100% - 55px) !important;
  }

  .ios & {

  }
}

.ceva-cu-zece {
  top: 40px;
}

::ng-deep .mat-step-label {
  color: white !important;  /* Force white text color */
}


// <PieseList>

.hide-advance-filters {
  .main-filters {
    width: 100% !important;
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .hide-controls {
    display: none !important;
  }

  .show-controls {
    display: flex !important;
  }

  mat-form-field:nth-child(2) {
    display: none;
  }
}


// <ProprietarItemView

.quill-html {
  overflow-y: auto;
  height: 195px;

  // override tailwind typography
  h1, h2 {
    color: white !important;
  }
  
  @media screen and (max-width: 1024px) {
    height: 158px;
  }
}

.scrollable-list {
  overflow-y: auto;
  height: 200px; // actual size of container
  width: 100%;

  mat-accordion {
    // display: flex;
    // flex-direction: column;
    // position: relative;
    // width: 100%;
    // height: 500px;
  }
}

.mat-expansion-panel {
  width: 100%;

  border: 1px solid #414141 !important;
  margin-bottom: 10px;
  // &:last-child {
  //   border-top-color: transparent !important;
  // }
}

.mat-expansion-panel-header {

}

.input-container {
  padding: 10px;
}


// chips
.mdc-evolution-chip-set__chips {
  white-space: nowrap !important;
  overflow-x: auto !important;
  /* max-width: 500px; */
  display: flex !important;
  flex-direction: row !important;
  flex-flow: nowrap !important;
}

